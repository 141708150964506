import { React, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { ReactMultiEmail } from "react-multi-email";
import { getrequest, postrequest } from "../../services/global.service";
import {
  Button,
  Loading,
  TextArea,
  Modal,
  Notification,
  toaster,
  Table,
  Column,
  DropdownButton,
  Panel,
} from "@appkit4/react-components";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

const baseURL = process.env.REACT_APP_API_BASE_URL;

const TestSurveyList = () => {
  const [rows, setRows] = useState([]);
  const [issendenable, setIssendenablee] = useState(true);
  const [deletesurveyData, setDeleteSurveyData] = useState({});
  const [shareQuestRowData, setshareQuestRowData] = useState({});
  const [surveyUsersmailSentDB, setsurveyUsersMailSentDB] = useState([]);
  const [isSentTextbox, setisSentTextbox] = useState(false);
  const [deleteReason, setDeleteReason] = useState("");

  const [deleteconfirmopen, setDeleteConfirmOpen] = useState(false);
  const [confirmUsersopen, setconfirmUsersopen] = useState(false);
  const [logDetailsopen, setlogDetailsopen] = useState(false);
  const [logDetails, setLogDetails] = useState({});
  const [logDetailsUserList, setLogDetailsUserList] = useState("");
  const [userMailId, setUserMailId] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sendMailError, setSendMailError] = useState("");
  const [saveLoading, setSaveLoading] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  Cookies.set("path", location.pathname, {
    sameSite: "strict",
    secure: true,
  });

  useEffect(() => {
    setIsLoading(true);
    getAllSurveys();
  }, []);

  const getAllSurveys = async () => {
    await getrequest(`${baseURL}/api/Survey/GetSurveyList`, {
      params: { teamName: JSON.parse(Cookies.get("seletedTeam")).name },
    })
      .then((response) => {
        setRows(JSON.parse(response.data.data));
        setIsLoading(false);
      })
      .catch((error) => {
        navigate("/service-problem");
      });
  }

  const activityLogClick = (rowData) => {
    setlogDetailsopen(true);
    const sentUsers = rowData.surveyUsers?.filter((data) => {
      return data.isEmailSent === true;
    });
    if (sentUsers?.length > 0) {
      var sentUsersList = sentUsers.map((object) => object.email);
      console.log(sentUsersList);
      setLogDetailsUserList(sentUsersList);
    }

    setLogDetails(rowData);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let findDuplicates = (arr) =>
      arr.filter((item, index) => arr.indexOf(item) != index);
    if (findDuplicates(userMailId).length > 0) {
      setSendMailError("Please remove duplicate emails and try again");
      return true;
    }

    let URL = `${baseURL}/api/Survey/AddSurveyUser?teamName=${
      JSON.parse(Cookies.get("seletedTeam")).name
    }`;
    let payloadData = {};

    payloadData = {
      id: shareQuestRowData.id,
      surveyId: shareQuestRowData.surveyid,
      surveyName: shareQuestRowData.surveyName,
      surveyUserEmail: userMailId.map((mail) => mail.toLowerCase()),
      teamName: JSON.parse(Cookies.get("seletedTeam")).name,
      newFileContent: JSON.parse(Cookies.get("seletedTeam")).name,
    };
    console.log(payloadData);
    console.log(payloadData.id);
    console.log(payloadData.surveyId);
    console.log(payloadData.surveyName);
    console.log(payloadData.surveyUserEmail);
    console.log(payloadData.teamName);
    setSaveLoading(true);
    postrequest(URL, payloadData)
      .then((response) => {
        if (response.data.message === "user already exist") {
          setSaveLoading(false);
          setSendMailError(
            "Questionnaire already sent to the below listed users: " +
              response.data.data
          );
        } else {
          setSendMailError(response.data.message);
          setSaveLoading(false);
          setIssendenablee(false);
        }
      })
      .catch((error) => {
        console.log("error reached")
        navigate("/service-problem");
      });
    
    console.log(payloadData);
    console.log(payloadData.id);
    console.log(payloadData.surveyId);
    console.log(payloadData.surveyName);
    console.log(payloadData.surveyUserEmail);
    console.log(payloadData.teamName);
  };

  const shareQuestinnaireClick = (rowData) => {
    setSendMailError("");
    setconfirmUsersopen(true);
    setIssendenablee(true);
    setsurveyUsersMailSentDB([]);
    setUserMailId([]);
    setisSentTextbox(false);
    console.log(rowData);
    if (rowData.surveyUsers != null) {
      const sentUsers = rowData.surveyUsers.filter((data) => {
        console.log(data);
        return data.isEmailSent === true;
      });
      if (sentUsers.length > 0) setisSentTextbox(true);
      var sentUsersList = sentUsers.map((object) => object.email);
      setsurveyUsersMailSentDB(sentUsersList);
      const savedUsers = rowData.surveyUsers.filter((data) => {
        return data.isEmailSent === false;
      });

      var savedusersList = savedUsers.map((object) => object.email);
      if (savedusersList.length > 0) setIssendenablee(false);
      setUserMailId(savedusersList);
    }

    setshareQuestRowData(rowData);
  }

  const sendClick = () => {
    sendEmail(shareQuestRowData);
  }

  const sendEmail = (data) => {
    var getSurveyDetailsURL = `${process.env.REACT_APP_API_BASE_URL}/api/Survey/SendEmail?surveyId=`;
    setIsLoading(true);
    postrequest(
      `${getSurveyDetailsURL}${data.surveyid}&surveyUrl=${
        process.env.REACT_APP_SURVEYURL
      }&userEmail=${
        JSON.parse(Cookies.get("OPENAMUSERDETAIL")).mail
      }&teamName=${JSON.parse(Cookies.get("seletedTeam")).name}`
    )
      .then((response) => {
        if (response.data.message === "Email call triggered") {
          setconfirmUsersopen(false);
          getAllSurveys();
          displayAlert("An email has been sent to the users");
        } else {
          navigate("/service-problem");
        }
      })
      .catch((error) => {
        navigate("/service-problem");
      });
  }

  const downloadResponse = (data) => {
    let surveyIdFilename = data.surveyid;
    console.log(surveyIdFilename);
    var getSurveyDetailsURL = `${process.env.REACT_APP_API_BASE_URL}/api/Survey/DownloadSurveyResponse?surveyId=`;
    getrequest(getSurveyDetailsURL + data.surveyid + `&teamName=${JSON.parse(Cookies.get("seletedTeam")).name}`, {
      responseType: 'blob',
    }).then((response) => {
      if (response.data === null) {
        navigate('/unauthorised')
      } else {            
      const url = window.URL.createObjectURL(new Blob([response.data]));
      console.log(url);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', surveyIdFilename + '.json'); //or any other extension
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      }
      }).catch((error)=>{       
        navigate('/service-problem')
    });
  }

  const deleteSurveyConfirmation = (rowData) => {
    setDeleteConfirmOpen(true);
    setDeleteSurveyData(rowData);
  }
  const sendReminder = (rowData) => {
    if (rowData.surveyUsers === null) {
      displayAlert("There are no survey users");
      return false;
    }
    var reminderURL = `${process.env.REACT_APP_API_BASE_URL}/api/Survey/ReminderMail?surveyId=`;
    postrequest(
      reminderURL +
        rowData.surveyid +
        `&surveyUrl=${process.env.REACT_APP_SURVEYURL}&teamName=` +
        JSON.parse(Cookies.get("seletedTeam")).name
    )
      .then((response) => {
        displayAlert(response.data.message);
      })
      .catch((error) => {
        navigate("/service-problem");
      });
  }
  const deleteSurvey = () => {
    setIsLoading(true);
    let URL =
      `${baseURL}/api/Survey/DeleteSurvey?surveyId=` +
      deletesurveyData.surveyid +
      `&teamName=` +
      JSON.parse(Cookies.get("seletedTeam")).name +
      `&reason=` +
      deleteReason;
    postrequest(URL)
      .then((response) => {
        setDeleteConfirmOpen(false);
        setDeleteSurveyData({});
        setDeleteReason("");
        getAllSurveys();
        displayAlert(response.data.message);
      })
      .catch((error) => {
        navigate("/service-problem");
      });
  }

  const getSurveyDetails = (row) => {
    navigate("/preview-survey/" + row.surveyid);
  };
  const showAllResponses = (row) => {
    navigate("/show-all-responses/" + row.surveyid);
  };
  const displayAlert = (text) => {
    return toaster.notify(
      <Notification title="Alert" message={text} status="" closeable />,
      { position: "topCenter", duration: 5000 }
    );
  };

  const handleVal = (val, row) => {
    if (val === "activityLog") {
      activityLogClick(row);
    } else if (val === "sendReminder") {
      sendReminder(row);
    } else if (val === "addVersion") {
      navigate("/upload", { state: row });
    } else if (val === "downloadResponses") {
      downloadResponse(row);
    } else if (val === "shareQuestionnaire") {
      shareQuestinnaireClick(row);
    } else if (val === "showAllResponses") {
      showAllResponses(row);
    }
  };

  if (isLoading)
    return (
      <Loading
        loadingType="linear"
        indeterminate={true}
        compact={false}
        className="page-loader"
      ></Loading>
    );

  return (
    <div className="ap-container">
      <Button
        onClick={() => {
          navigate("/teams");
        }}
        kind="text"
        className="back-button"
      >
        Back
      </Button>

      {JSON.parse(Cookies.get("seletedTeam")).isActive && (
        <>
          <Panel
            title={JSON.parse(Cookies.get("seletedTeam")).name}
            className="mb-md-3"
          >
            <div className="row mt-md-3">
              {(JSON.parse(Cookies.get("seletedTeam")).roleId ===
                "Content Creator" ||
                JSON.parse(Cookies.get("seletedTeam")).roleId === "Admin") && (
                <div className="col-4">
                  <Button
                    kind="secondary"
                    icon="icon-survey-outline"
                    onClick={() => navigate("/upload")}
                  >
                    Create a Questionnaire
                  </Button>
                </div>
              )}
              {JSON.parse(Cookies.get("seletedTeam")).roleId === "Admin" && (
                <>
                  <div className="col-4">
                    <Button
                      kind="secondary"
                      icon="icon-community-outline"
                      onClick={() => navigate("/adduploaders")}
                    >
                      Manage Team
                    </Button>
                  </div>
                </>
              )}
            </div>
          </Panel>
          <Table originalData={rows} hasTitle striped>
            <Column field="surveyName">Name</Column>
            <Column field="updatedBy">Updated By</Column>
            <Column
              field="surveyName"
              renderCell={(row) =>
                (JSON.parse(Cookies.get("seletedTeam")).roleId === "Admin" ||
                  JSON.parse(Cookies.get("seletedTeam")).roleId ===
                    "Content Creator") &&
                row.isActive &&
                !row.isExpired && (
                  <Button
                    variant="text"
                    icon="icon-view-outline"
                    onClick={() => getSurveyDetails(row)}
                  >
                    View
                  </Button>
                )
              }
            />
            <Column
              field="surveyName"
              renderCell={(row) =>
                JSON.parse(Cookies.get("seletedTeam")).roleId === "Admin" &&
                JSON.parse(Cookies.get("seletedTeam")).isActive &&
                row.isActive && (
                  <Button
                    variant="text"
                    icon="icon-delete-outline"
                    onClick={() => deleteSurveyConfirmation(row)}
                  >
                    Delete
                  </Button>
                )
              }
            />
            <Column
              field="surveyName"
              renderCell={(row) => (
                <div
                  style={{ display: "flex", justifyContent: "space-evenly" }}
                >
                  <DropdownButton
                    splitButton={false}
                    onSelect={(val) => handleVal(val, row)}
                    data={[
                      {
                        label: "Activity Log",
                        value: "activityLog",
                        disabled:
                          (JSON.parse(Cookies.get("seletedTeam")).roleId !==
                            "Admin" ||
                            JSON.parse(Cookies.get("seletedTeam")).roleId !==
                              "Content Creator") &&
                          (!JSON.parse(Cookies.get("seletedTeam")).isActive ||
                            !row.isEmailSent),
                      },
                      {
                        label: "Send Reminder",
                        value: "sendReminder",
                        disabled:
                          (JSON.parse(Cookies.get("seletedTeam")).roleId !==
                            "Admin" ||
                            JSON.parse(Cookies.get("seletedTeam")).roleId !==
                              "Content Creator") &&
                          (!row.isEmailSent || !row.isActive || row.isExpired),
                      },
                      {
                        label: "Download Responses",
                        value: "downloadResponses",
                        disabled:
                          JSON.parse(Cookies.get("seletedTeam")).roleId !==
                            "Admin" &&
                          JSON.parse(Cookies.get("seletedTeam")).roleId !==
                            "Data Extractor",
                      },
                      {
                        label: "Share",
                        value: "shareQuestionnaire",
                        enabled:
                          (JSON.parse(Cookies.get("seletedTeam")).roleId ===
                            "Admin" ||
                            JSON.parse(Cookies.get("seletedTeam")).roleId ===
                              "Content Creator") 
                      },
                      {
                        label: "Add Version",
                        value: "addVersion",
                        disabled: !Object.keys(row).length,
                      },
                      {
                        label: "Show All Responses",
                        value: "showAllResponses",
                        disabled:
                          (JSON.parse(Cookies.get("seletedTeam")).roleId !==
                            "Admin" ||
                            JSON.parse(Cookies.get("seletedTeam")).roleId !==
                              "Content Creator") &&
                          (!row.isActive ||
                            !JSON.parse(Cookies.get("seletedTeam")).isActive ||
                            row.isExpired),
                      },
                    ]}
                  >
                    Actions
                  </DropdownButton>
                </div>
              )}
            />
          </Table>

          <Modal
            visible={deleteconfirmopen}
            title="Delete Questionnaire"
            onCancel={() => setDeleteConfirmOpen(false)}
            modalStyle={{ width: "33.75rem" }}
            footerStyle={{
              paddingTop: "8px",
              marginTop: "-8px",
              minHeight: "64px",
            }}
            footer={
              <Button
                disabled={!deleteReason}
                onClick={deleteSurvey}
                kind="negative"
              >
                Delete
              </Button>
            }
            bodyStyle={{ minHeight: "92px" }}
          >
            <p>
              Once the questionnaire is deleted it cannot be undone, and users
              can no longer submit responses. Are you sure to continue?
            </p>
            <p>
              If you do not wish to send an email to the above recipients,
              please close the modal.
            </p>
            <br></br>
            <TextArea
              maxLength={480}
              required
              type="text"
              name="deleteReason"
              id="deleteReason"
              title="Reason For Deleting Questionnaire"
              value={deleteReason}
              onChange={(value) => setDeleteReason(value)}
            />
          </Modal>

          <Modal
            visible={confirmUsersopen}
            title="Share Questionnaire"
            onCancel={() => setconfirmUsersopen(false)}
            modalStyle={{ width: "33.75rem" }}
            footerStyle={{
              paddingTop: "8px",
              marginTop: "-8px",
              minHeight: "64px",
            }}
            footer={
              <Button disabled={issendenable} onClick={sendClick}>
                Send
              </Button>
            }
            bodyStyle={{ minHeight: "92px" }}
          >
            {isSentTextbox && (
              <TextArea
                name="sentEmailId"
                id="sentEmailId"
                title="Mail sent to"
                maxLength={820}
                disabled
                defaultValue={surveyUsersmailSentDB?.join(", ")}
                className="mb-md-3"
              />
            )}

            <ReactMultiEmail
              placeholder="Emails"
              emails={userMailId || []}
              onChange={(emails) => {
                setUserMailId(emails);
              }}
              getLabel={(email, index, removeEmail) => {
                return (
                  <div data-tag key={index}>
                    <div data-tag-item>{email}</div>
                    <span data-tag-handle onClick={() => removeEmail(index)}>
                      ×
                    </span>
                  </div>
                );
              }}
            />

            <div className="ap-field-email-validation-error">
              {sendMailError}
            </div>

            <Button
              disabled={!userMailId.length}
              onClick={handleSubmit}
              className="mb-md-3"
              loading={saveLoading}
            >
              Save
            </Button>
            <br />
            <p>
              By clicking send an email will be sent to all recipients with a
              link to the {shareQuestRowData.surveyName} questionnaire.
            </p>
            <br></br>
            <p>
              If you do not wish to send an email to the above recipients,
              please close the modal.
            </p>
          </Modal>

          <Modal
            visible={logDetailsopen}
            title="Log details"
            ariaLabel="Log details"
            onCancel={() => setlogDetailsopen(false)}
            modalStyle={{ width: "33.75rem" }}
            footerStyle={{
              paddingTop: "8px",
              marginTop: "-8px",
              minHeight: "64px",
            }}
            bodyStyle={{ minHeight: "92px" }}
          >
            <p>Survey name: {logDetails.surveyName}</p>
            <p>Survey sent by: {logDetails.emailSentBy}</p>
            <p>
              Survey sent on:{" "}
              {moment(logDetails.emailSentOn).format("DD/MM/YYYY")}
            </p>
            <p>
              Survey sent to:{" "}
              {logDetailsUserList ? logDetailsUserList.join(", ") : "No one"}
            </p>
          </Modal>
        </>
      )}
    </div>
  );
}
export default TestSurveyList;